
// import React from 'react'
import $ from 'jquery'

export const HeaderAnim = () =>{

  (function( $ ){
  
    $.fn.textAnimation = function( animation_speed, text_speed, animation ){
      var text, i = 0;
      var $this = $(this);
      
      // store text and clear
      text = $this.text();
      $this.css('white-space', 'pre');
      $this.html('');
      
      var addLetter = setInterval(function(){
        $this.append('<div class="text_animation" style="display: inline-block; animation: ' + animation + ' ' + animation_speed + 'ms forwards">' + text[i] + '</div>');
        i++;
        if (i === text.length) clearInterval(addLetter);
      }, text_speed);
    }
  })( $ )
  
  
  // var temp = $('.header--anim').text();
  // var i = 1;
  
  $('.header--anim').textAnimation(600, 50, 'headerAnim1');
}
 

